<template>
  <div>
    <b-card title="Mes notifications">
      <div class="d-flex justify-content-end">
        <b-form-group>
          <div class="d-flex align-items-center">
            <label class="mr-1 text-uppercase">Etat</label>
            <b-form-select
              v-model="currentState"
              :options="stateOptions"
            />
          </div>
        </b-form-group>
      </div>
      <b-table
        hover
        responsive
        show-empty
        empty-text="Aucune notification"
        :items="items"
        :fields="fields"
        :busy="loaded"
      >
        <template #table-busy>
          <div class="d-flex justify-content-center mb-3">
            <b-spinner
              variant="primary"
              label="Loading..."
            />
          </div>
        </template>
        <template v-slot:cell(date)="data">
          {{ data.item.updated_at | moment("DD/MM/YY HH:mm") }}
        </template>
        <template v-slot:cell(action)="data">
          <span class="whitespace-no-wrap">
            <feather-icon
              icon="EditIcon"
              svg-classes="w-5 h-5 hover:text-success stroke-current"
              class="text-warning"
              @click="openModal(data.item)"
            />
          </span>
          <span class="whitespace-no-wrap">
            <feather-icon
              icon="TrashIcon"
              svg-classes="w-5 h-5 hover:text-danger stroke-current"
              class="text-danger ml-1"
              @click="openDeleteModal(data.item)"
            />
          </span>
        </template>
      </b-table>
      <div class="mt-2">
        <div class="float-left">
          <b-form-select
            v-model="perPage"
            :options="pageOptions"
          />
        </div>
        <b-pagination
          v-model="currentPage"
          :total-rows="totalItems"
          :per-page="perPage"
          first-number
          last-number
          align="right"
        />
      </div>
    </b-card>
    <b-modal
      v-if="showModal"
      v-model="showModal"
      centered
      hide-footer
      title="Modification"
      :no-close-on-backdrop="process"
      :hide-header-close="process"
      :busy="process"
    >
      <div>
        <b-row>
          <b-col
            cols="12"
          >
            <b-form-group label="Type d'information">
              <b-form-select
                v-model="$v.currentNote.type.$model"
                :options="typeOptions"
                :class="{ 'is-invalid': $v.currentNote.type.$error }"
              />
            </b-form-group>
          </b-col>
          <b-col
            cols="12"
          >
            <b-form-group label="Etat">
              <b-form-select
                v-model="$v.state.$model"
                :options="showOption"
                :class="{ 'is-invalid': $v.state.$error }"
              />
            </b-form-group>
          </b-col>
          <b-col
            cols="12"
          >
            <b-form-textarea
              v-model="$v.currentNote.message.$model"
              :class="{ 'is-invalid': $v.currentNote.message.$error }"
              rows="3"
            />
            <div
              v-if="$v.currentNote.message.$error"
              class="invalid-feedback"
            >
              <span
                v-if="!$v.currentNote.message.required"
              >{{ $t('errors.required') }}</span>
              <span
                v-if="!$v.currentNote.message.maxLength"
              >Maximun 200 caractères.</span>
            </div>
          </b-col>
          <b-col
            v-if="currentNote.type === 'MESSAGE'"
            cols="12"
          >
            <b-form-group label="Fichier">
              <b-form-file
                v-model="$v.currentNote.file.$model"
                accept=".jpg, .jpeg, .png"
                placeholder="Choisir une image"
                :class="{ 'is-invalid': $v.currentNote.file.$error }"
                browse-text="Parcourir"
                @change="handleFile($event)"
              />
              <div
                v-if="$v.currentNote.file.$error"
                class="invalid-feedback"
              >
                <span
                  v-if="!$v.currentNote.file.required"
                >{{ $t('errors.required') }}</span>
              </div>
            </b-form-group>
          </b-col>
          <b-col cols="12">
            <div
              v-if="process"
              class="d-flex justify-content-center mt-1"
            >
              <b-spinner
                variant="primary"
                label="Loading..."
              />
            </div>
            <div class="mt-3">
              <b-button
                type="submit"
                variant="outline-primary"
                class="ml-2 float-right"
                :disabled="process"
                @click="tryToUpdate"
              >
                Modifier
              </b-button>
            </div>
          </b-col>
        </b-row>
      </div>
    </b-modal>
    <b-modal
      v-if="showDeleteModal"
      v-model="showDeleteModal"
      centered
      hide-footer
      title="Suppression"
      :no-close-on-backdrop="delProcess"
      :hide-header-close="delProcess"
      :busy="delProcess"
    >
      <div>
        <div class="p-2">
          Voulez-vous supprimer la notification du système ?
        </div>
        <b-row>
          <b-col cols="12">
            <div
              v-if="delProcess"
              class="d-flex justify-content-center mt-1"
            >
              <b-spinner
                variant="danger"
                label="Loading..."
              />
            </div>
            <div class="mt-3">
              <b-button
                type="submit"
                variant="outline-danger"
                class="ml-2 float-right"
                :disabled="delProcess"
                @click="tryToDelete"
              >
                Supprimer
              </b-button>
            </div>
          </b-col>
        </b-row>
      </div>
    </b-modal>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { required, requiredIf, maxLength } from 'vuelidate/lib/validators'

export default {
  data() {
    return {
      items: [],
      currentPage: 1,
      currentState: 1,
      loaded: false,
      perPage: 10,
      totalItems: 0,
      showModal: false,
      showDeleteModal: false,
      showOption: [
        { value: true, text: 'Affiché' },
        { value: false, text: 'Non affiché' },
      ],
      stateOptions: [
        { value: 1, text: 'Affiché' },
        { value: 0, text: 'Non affiché' },
      ],
      pageOptions: [5, 10, 15],
      currentNote: {
        id: null,
        message: null,
        type: null,
        file: null,
      },
      typeOptions: [
        { value: null, text: 'Sélectionner', disabled: true },
        // { value: 'MESSAGE', text: 'MESSAGE' },
        { value: 'NOTIFICATION', text: 'NOTIFICATION' },
      ],
      state: null,
      fields: [
        { key: 'type', value: 'Type' },
        { key: 'information', value: 'Notification' },
        { key: 'date', value: 'Date' },
        { key: 'action', value: 'Action' },
      ],
    }
  },
  validations: {
    state: {
      required,
    },
    currentNote: {
      message: {
        required,
        maxLength: maxLength(200),
      },
      type: {
        required,
      },
      file: {
        // eslint-disable-next-line func-names
        required: requiredIf(function () {
          if (this.currentNote.type === 'MESSAGE') {
            return true
          }
          return false
        }),
      },
    },
  },
  computed: {
    ...mapGetters({
      success: 'updateNoteSuccess',
      error: 'updateNoteError',
      process: 'updateNoteProcess',

      delSuccess: 'deleteNoteSuccess',
      delError: 'deleteNoteError',
      delProcess: 'deleteNoteProcess',
    }),
  },
  watch: {
    $route: {
      immediate: true,
      handler() {
        this.getAllNotifications()
      },
    },
    currentState() {
      this.getAllNotifications()
    },
    delSuccess(val) {
      if (val) {
        this.closeDeleteModal()
        this.getAllNotifications()
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Suppression de la notification',
            text: val.message,
            icon: 'CheckCircleIcon',
            variant: 'success',
          },
        })
      }
    },
    delError(val) {
      if (val) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Suppression de la notification',
            text: 'Une erreur est survenue',
            icon: 'XCircleIcon',
            variant: 'danger',
          },
        })
      }
    },
    success(val) {
      if (val) {
        this.closeModal()
        this.getAllNotifications()
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Modification de la notification',
            text: 'Modifié avec succes',
            icon: 'CheckCircleIcon',
            variant: 'success',
          },
        })
      }
    },
    error(val) {
      if (val) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Modification de la notification',
            text: 'Une erreur est survenue',
            icon: 'XCircleIcon',
            variant: 'danger',
          },
        })
      }
    },
  },
  methods: {
    ...mapActions(['updateNote', 'deleteNote']),
    openModal(item) {
      this.currentNote = {
        message: item.information,
        type: item.type,
        id: item.id,
        file: null,
      }
      this.state = item.state
      this.showModal = true
    },
    closeModal() {
      this.currentNote = null
      this.showModal = false
    },
    openDeleteModal(item) {
      this.currentNote.id = item.id
      this.showDeleteModal = true
    },
    closeDeleteModal() {
      this.currentNote = null
      this.showDeleteModal = false
    },
    tryToUpdate() {
      this.$v.$touch()
      if (this.$v.$anyError) {
        return
      }
      this.updateNote({
        id: this.currentNote.id,
        information: this.currentNote.message,
        state: this.state,
        type: this.currentNote.type,
        file: this.currentNote.file,
      })
    },
    tryToDelete() {
      this.deleteNote(this.currentNote.id)
    },
    async getAllNotifications() {
      this.loaded = true
      this.items = await this.$http
        .get(`/information/all-state-and-type/${this.currentState}/NOTIFICATION/${this.perPage}?page=${this.currentPage}`,
          {
            headers: {
              Authorization: `Bearer ${this.$store.getters.currentToken}`,
            },
          })
        .then(res => {
          const { data } = res
          this.loaded = false
          this.totalItems = data.meta.total
          return data.data
        })
        .catch(() => {
          this.loaded = false
          return []
        })
    },
  },
}
</script>

<style>

</style>
